'use client';
import React, { useState } from 'react';
import Image from 'next/image';
import { FormPopup } from '@/components/FormPopup';
import { getStrapiMedia } from '@/lib/media';
import { NoDataFound } from '@/components/NoData';
import { useGlobalStore } from '@/app/stores/useGlobalStore';

function SurgeryContainer({ treatmentList }: any) {
  const [showBookingForm, setBookingForm] = useState(false);
  const { ctaLabel, setCtaLabel } = useGlobalStore();

  return (
    <section>
      {/* desktop section */}
      <section className=' hidden place-items-center sm:grid sm:grid-cols-7 sm:grid-rows-2'>
        {treatmentList?.length ? (
          treatmentList?.slice(0, 13)?.map((item: any) => {
            return (
              <section
                key={item.id}
                className=' py-auto h-[120px] w-[100px] sm:h-[150px] sm:w-[150px]'
              >
                <Image
                  src={getStrapiMedia(item?.attributes?.treatmentIcon)}
                  alt={''}
                  width={120}
                  height={120}
                  onClick={() => {
                    setBookingForm(true),
                      setCtaLabel('Hero Section Treatmentbox cta');
                  }}
                  className='mx-auto mb-[10px] h-[80px] w-[80px] cursor-pointer rounded-[15px] bg-[#e3f0f8] dark:bg-[#3b515e] sm:h-[90px] sm:w-[90px]'
                />
                <p className='text-center text-[9px] font-medium tracking-[-.28px] text-[#1c2534] dark:text-white sm:text-[12px] '>
                  {item?.attributes?.treatmentName}
                </p>
              </section>
            );
          })
        ) : (
          <NoDataFound />
        )}
        <section className=' py-auto h-[120px] w-[100px] sm:h-[150px] sm:w-[150px]'>
          <section className='mx-auto mb-[10px] flex h-[85px] w-[85px] items-center  justify-center rounded-[15px] bg-[#e3f0f8] dark:bg-[#3b515e] sm:h-[90px] sm:w-[90px]'>
            <p
              onClick={() => {
                setBookingForm(true);
                setCtaLabel('Hero Section Treatmentbox cta');
              }}
              className='text-center text-[30px] font-semibold text-[#4e667b] dark:text-white'
            >
              50+
            </p>
          </section>
          <p className='whitespace-nowrap text-center text-[12px] font-medium tracking-[-.28px] text-[#1c2534] dark:text-white sm:text-[12px] '>
            Surgeries
          </p>
        </section>
      </section>
      {/* mobile section */}
      <section className='grid grid-cols-3 place-items-center sm:hidden '>
        {treatmentList?.length ? (
          treatmentList?.slice(0, 8)?.map((item: any) => (
            <section
              key={item.id}
              className=' py-auto h-[120px] w-[100px] sm:h-[150px] sm:w-[150px]'
            >
              <Image
                src={getStrapiMedia(item?.attributes?.treatmentIcon)}
                alt={''}
                width={120}
                height={120}
                onClick={() => setBookingForm(true)}
                className='mx-auto mb-[10px] h-[85px] w-[85px] rounded-[15px] bg-[#e3f0f8] dark:bg-[#3b515e] sm:h-[90px] sm:w-[90px]'
              />
              <p className='whitespace-nowrap text-center text-[12px] font-medium tracking-[-.28px] text-[#1c2534] dark:text-white sm:text-[12px] '>
                {item?.attributes?.treatmentName}
              </p>
            </section>
          ))
        ) : (
          <NoDataFound />
        )}
        <section className=' py-auto h-[120px] w-[100px] sm:h-[150px] sm:w-[150px]'>
          <section className='mx-auto mb-[10px] flex h-[85px] w-[85px] items-center  justify-center rounded-[15px] bg-[#e3f0f8] dark:bg-[#3b515e] sm:h-[90px] sm:w-[90px]'>
            <p
              onClick={() => setBookingForm(true)}
              className='text-center text-[30px] font-semibold text-[#4e667b] dark:text-white'
            >
              50+
            </p>
          </section>
          <p className='whitespace-nowrap text-center text-[12px] font-medium tracking-[-.28px] text-[#1c2534] dark:text-white sm:text-[12px] '>
            Surgeries
          </p>
        </section>
      </section>

      <FormPopup
        openPopup={showBookingForm}
        setOpenPopup={setBookingForm}
        setShowToast={null}
      />
    </section>
  );
}

export default SurgeryContainer;

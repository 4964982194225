import Image from 'next/image';
// import BlogCardDesktop from './BlogCardDesktop';
// import BlogCardMobile from './BlogCardMobile';
import MenuIcon from '@/public/homePage/BlogSideBar/ellipsis.svg';
// import shareIcon from '@/public/BlogDetails/actions-V1.svg';
import { getStrapiMedia } from '@/lib/media';
import { PostDetails } from './PostDetails';
import { BodySmall, HeadingText } from '@/components/DynamicText';
import Link from 'next/link';

const BlogCard = (props: any) => {
  const {
    attributes: {
      title,
      description,
      card_image,
      // author,
      slug,
      readingTime,
      // publishedAt,
    },
    // id,
  } = props;
  return (
    <Link href={`/${slug}`} className='w-full cursor-pointer '>
      <section
        className='mb-[20px] hidden min-h-[100px]
      w-full rounded-[20px] border bg-white dark:bg-[#263843] sm:flex sm:border-[#eaedf3] sm:p-[20px] dark:sm:border-none '
      >
        <section className='mr-[30px] h-[auto] '>
          {card_image && card_image?.data && card_image?.data?.attributes && (
            <Image
              src={getStrapiMedia(card_image)}
              alt='featured image'
              width={80}
              height={80}
              className='max-h-[150px] min-h-[150px] min-w-[150px] max-w-[150px] rounded-[15px]'
            />
          )}
        </section>
        <section className='flex w-full flex-col'>
          <section className='flex w-full justify-between'>
            <HeadingText className='dark mb-[15px] line-clamp-1 font-semibold leading-[1.56] tracking-[-0.36px] text-[#1c2534] dark:text-white'>
              {title}
            </HeadingText>
            <Image
              className='ml-[10px] cursor-pointer self-start'
              src={MenuIcon}
              alt='Menu Icon'
            />
          </section>
          <BodySmall
            className='mb-[18px] line-clamp-2 w-11/12
          font-medium leading-[1.70] tracking-[-.26px] text-[#1c2534] dark:text-[#9ea8ac]'
          >
            {description}
          </BodySmall>
          <section className='mt-auto'>
            <PostDetails {...props?.attributes} />
          </section>
        </section>
      </section>
      {/* mobile >>>>>>>>>>>>>>>>>>>>>>>*/}
      <section
        className='my-[20px] flex h-auto
          rounded-[20px] border border-[#eaedf3] p-[15px] dark:border-none dark:bg-[#263843] sm:hidden'
      >
        <section className=' w-full'>
          <section className='mb-[12px] flex justify-start gap-[13px]'>
            <section className=''>
              <Image
                className='min-h-[70px]  min-w-[70px] rounded-[15px]'
                src={getStrapiMedia(card_image)}
                alt=''
                width={80}
                height={80}
              />
            </section>

            <section className='flex flex-col justify-between'>
              <HeadingText className='mb-[4px] line-clamp-2 pr-[10px] text-[15px] font-semibold leading-[1.47] tracking-[-.36px] text-[#1c2534]   dark:text-white'>
                {title}
              </HeadingText>

              <BodySmall className='font-medium tracking-[-.24px] text-[#1c2534] dark:text-[#9ea8ac]'>
                {readingTime}
              </BodySmall>
            </section>

            <Image src={MenuIcon} alt='menu' className='ml-auto self-start' />
          </section>

          <BodySmall
            className='mb-[12px] line-clamp-2 text-[14px] font-medium leading-[1.70]
              tracking-[-.26px] text-[#1c2534] dark:text-[#9ea8ac]'
          >
            {description}
          </BodySmall>
          <section className='relative flex justify-between'>
            <PostDetails {...props?.attributes} />
          </section>
        </section>
      </section>

      {/* <BlogCardDesktop {...props} />
      <BlogCardMobile {...props} /> */}
    </Link>
  );
};

export default BlogCard;

'use client';
import React, { Suspense, useState } from 'react';
import BlogCard from './blogCard/BlogCard';
import { getArticles } from '@/app/services/ArticleService';
import { getArticlesDetailsCategorySlug } from '@/app/services/ArticleService';
export const revalidate = 0;

export default function ArticlesCardList({
  whichApi,
  searchPageCategory = '',
}: {
  whichApi: 'mainPage' | 'searchPage';
  searchPageCategory?: string;
}) {
  const [articleList, setArticleList] = useState<any>({});
  const [isPageCount, setPageCount] = useState(2);

  let api: (count: number) => any;
  switch (whichApi) {
    case 'mainPage':
      api = getArticles;
      break;
    case 'searchPage':
      api = (count: number) =>
        getArticlesDetailsCategorySlug(searchPageCategory, '', count);
  }
  const fetchArticles = async (count: number) => {
    const res = await api(count);
    if (articleList.data) {
      setArticleList((prev: any) => {
        return { meta: res.meta, data: [...prev.data, ...res.data] };
      });
    } else {
      setArticleList(res);
    }
  };

  const fetchMoreArticles = () => {
    setPageCount(isPageCount + 1);
    fetchArticles(isPageCount);
  };
  return (
    <Suspense fallback={<div className='flex justify-center'>Loading</div>}>
      <div>
        {Boolean(articleList?.data?.length) &&
          articleList?.data?.map((article: any) => (
            <React.Fragment key={`blogca:${article.id}`}>
              <BlogCard key={`blogccard2:${article.id}`} {...article} />
            </React.Fragment>
          ))}
        {(articleList?.meta?.pagination?.page <
          articleList?.meta?.pagination?.pageCount ||
          isPageCount === 2) && (
          <div className='flex justify-center'>
            <button
              className='mt-[80px] rounded-[10px] border border-[#1c2534] bg-[#fff] p-[12px_22px] text-[12px] font-[500] text-[#1c2534] hover:bg-[#1c2534] hover:text-[#fff] dark:border-[#ffffff] max-sm:mt-[30px]'
              onClick={fetchMoreArticles}
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </Suspense>
  );
}

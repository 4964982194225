import Image from 'next/image';
import { getStrapiMedia } from '@/lib/media';
import SocialShare from './SocialShare';
import { FootNote } from '@/components/DynamicText';

export function PostDetails(props: any) {
  const { category, author, readingTime, publishedAt, slug, title } = props;
  const options: any = { year: 'numeric', month: 'long', day: '2-digit' };
  const formattedDate = new Date(publishedAt).toLocaleDateString(
    'en-US',
    options
  );
  return (
    <section>
      <section className='box-border flex w-full flex-wrap items-center justify-start gap-y-[20px] rounded-xl   sm:relative sm:border-[#e8ddc0] '>
        {/* author  */}
        <section className=' flex items-center rounded-[18px] bg-[#f1f8f9] py-[4px] dark:bg-[#3f525d] sm:static'>
          <Image
            className='mx-1.5 h-[26px] w-[26px] rounded-full'
            width={10}
            height={10}
            src={getStrapiMedia(author?.data?.attributes?.profile_pic)}
            alt={`${author?.data?.attributes?.name}`}
          />
          <FootNote className='mr-4 font-semibold text-[#1c2534] dark:text-white'>
            {author?.data?.attributes?.name}
          </FootNote>
        </section>
        {/* posttype */}
        <FootNote className='mx-3 rounded-3xl border  border-[#e8ddc0] bg-[#f9f2dd] px-4 py-2 font-semibold dark:border-[#9ea8ac]  dark:bg-[#263843] dark:text-[#9ea8ac]'>
          {category?.data?.attributes?.name}
        </FootNote>
        {/* date published */}
        <FootNote className='mr-[10px] border-r border-[#d0d8e9] pr-[10px] font-medium tracking-[-.26px] dark:text-[#9ea8ac]'>
          {formattedDate}
        </FootNote>
        {/* reading count */}
        {/* <FootNote className='mr-[10px] border-[#d0d8e9] pr-[10px] font-medium tracking-[-.26px] dark:text-[#9ea8ac] lg:border-r'>
          <span className='font-semibold tracking-[-.26px] dark:text-[#9ea8ac]'>
            1045
          </span>{' '}
          Reads
        </FootNote> */}
        {/* reading time */}
        <FootNote className='font-medium text-[#1c2534] dark:text-[#9ea8ac] max-sm:hidden'>
          {readingTime}
        </FootNote>
        {/* share icon */}
        <SocialShare title={title} url={`${slug}`} />
      </section>
    </section>
  );
}
